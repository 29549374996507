import React from 'react';
import styles from "./css/App.module.css"
import Header from './Components/Header';


type P = {

};

type S = {
  renderedComponent?:React.ReactElement
};

class App extends React.Component<P, S> {

  constructor(props: P) {
    super(props);

    this.state = {}
  }

  renderer = (component:React.ReactElement) => {
    
    this.setState({
      renderedComponent:component
    })
  }

  render() {
    return (
      <div className={styles.app}>
        <Header renderProvider={(component) => this.renderer(component)}/>
        {this.state.renderedComponent}
      </div>
    );
  }
}
export default App;