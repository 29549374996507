import React from "react";
import styles from "../css/About.module.css"

type P = {
    
};

type S = {
    
};

class About extends React.Component<P, S> {

    constructor(props:P){
        super(props)

        this.state = {

        }
    }

    

    render() {
        return (
           <div className={styles.container}>

               <div className={styles.card}>
                    <h1>Hello, My Name is Moses Santos</h1>

                    <textarea cols={100} disabled={true}>
                        I'm a full-stack developer with a heavy specialization into software design and architecture.
                        Please feel free to browse through my projects and take a look at my experience in the field. 
                        If you are interested you can get into contact via email or by using the contact form provided on my website.
                    </textarea>
               </div>
           </div> 
        );
    }
}

export default About;