import React from "react";
import styles from "../css/Project.module.css"
import ProjectOverlay from "../Components/ProjectOverlay";

import capstoneimg from "../Logos/capstoneimg.png"
import compilerimg from "../Logos/compilerimg.png"
import inventoryWebsite from "../Logos/inventoryWebsite.png"
import inventoryBackend from "../Logos/inventoryBackend.png"


type P = {

};

type S = {
    isAnimating?: boolean
};

class Projects extends React.Component<P, S> {

    constructor(props: P) {
        super(props)

        this.state = {
            isAnimating: true
        }
    }

    render() {
        return (
            <div className={styles.container}>

                <div className={styles.messageDiv}>
                    Check out my custom built professional solutions using Reactjs And TypeScript or independent and school projects like my custom built frontend compiler.
                </div>


                <table className={styles.projectTable}>
                    <tbody>
                        <tr>
                            <ProjectOverlay src={capstoneimg} redirectUrl='https://github.com/MosesSantos/Capstone-ML-Project' title="AI Branding Recognition Software">
                                <>
                                    <div>JavaScript</div>
                                    <div>ReactJs</div>
                                    <div>NodeJS</div>
                                    <div>Python</div>
                                    <div>Flask</div>
                                    <div>Css - Html</div>
                                </>
                            </ProjectOverlay>

                            <ProjectOverlay src={compilerimg} redirectUrl='https://github.com/MosesSantos/Mtyped-Compiler' title="Custom Compiler">
                                <>
                                    <div>C</div>
                                    <div>CMake</div>
                                    <div>Regex</div>
                                </>
                            </ProjectOverlay>
                        </tr>

                        <tr>
                            <ProjectOverlay
                                src={inventoryWebsite}
                                redirectUrl=""
                                githubLink={false}
                                title="Inventory Manager Website"
                                infoText={
                                    <textarea>
                                        Custom built frontend enterprize resource planning solution for my current employer than gives office 
                                        adminastration the ability to interface with the system. This was built for a full service car hoist installer 
                                        and is able to manager all day to day operations. It was design for to allow for greater efficeny and reduce the about of human error.
                                    </textarea>
                                }

                            >
                                <>
                                    <div>JavaScript</div>
                                    <div>TypeScript</div>
                                    <div>ReactJs</div>
                                    <div>NodeJS</div>
                                    <div>Css - Html</div>
                                </>
                            </ProjectOverlay>

                            <ProjectOverlay
                                src={inventoryWebsite}
                                redirectUrl=""
                                githubLink={false}
                                title="Inventory Manager App"
                                infoText={
                                    <textarea>
                                        This application is used for service technicians in the field as to keep accurate record of stock
                                        and is able to keep scheduling in order as to improve productivity.
                                    </textarea>}
                            >
                                <>
                                    <div>JavaScript</div>
                                    <div>TypeScript</div>
                                    <div>React Native</div>
                                    <div>NodeJS</div>
                                    <div>Css - Html</div>
                                </>
                            </ProjectOverlay>
                        </tr>
                        <tr>
                            <ProjectOverlay
                                src={inventoryBackend}
                                redirectUrl=""
                                githubLink={false}
                                title="Inventory Manager Backend"
                                infoText={
                                    <textarea>
                                        This Handles all of the bussniess logictics of the business. It does this by implementing Quickbooks OAuth2 api to manage the books.
                                        By using Mysql, The software is able to handle large data such as stock quantities and service technicians schedules. 
                                    </textarea>
                                }
                                
                            >
                            <>
                                <div>JavaScript</div>
                                <div>TypeScript</div>
                                <div>ExpressJs</div>
                                <div>My Sql</div>
                                <div>OAuth2</div>
                                <div>NodeJS</div>
                            </>
                        </ProjectOverlay>
                    </tr>
                </tbody>
            </table>
            </div >
        );
    }
}

export default Projects;