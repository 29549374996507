import React from "react";

import styles from "../css/ProjectOverlay.module.css"

import githublogo from "../Logos/githublogo.png"
import infoLogo from "../Logos/infoIcon.png"


type P = {
    src: string
    redirectUrl: string
    children: React.ReactElement
    githubLink?: boolean
    title: string
    infoText?:React.DetailedHTMLProps<React.HTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement>
};

type S = {
    showInfo: boolean

};

class ProjectOverlay extends React.Component<P, S> {

    constructor(props: P) {
        super(props);

        this.state = {
            showInfo: false
        }

    }

    render() {
        return (
            <td className={styles.item}>
                <div className={styles.divcon}>

                    <img className={styles.image} src={this.props.src} alt="" />

                    <div className={styles.overlay} onMouseLeave={() => this.setState({showInfo:false})}>

                    <h2>{this.props.title}</h2>

                        {this.state.showInfo === false ?

                            <>

                          
                                Skills Used:

                                <div className={styles.overlayButtons}>
                                    {this.props.children}
                                </div>
                            </>
                            :

                            <>

                                {this.props.infoText ? React.cloneElement(this.props.infoText as React.ReactElement, {className:styles.infoTextArea, rows:10, disabled:true}) : undefined}
                            
                            </>
                            
                           

                            
                            
                           
                        }


                        {this.props.githubLink === false ?

                            <div className={styles.infoGit}>
                                <img src={infoLogo} alt="" onClick={() => this.setState({ showInfo: !this.state.showInfo })} />
                            </div>

                            :
                            <div className={styles.infoGit}>
                                <img src={githublogo} alt="" onClick={() => window.open(this.props.redirectUrl)} />
                            </div>
                        }


                    </div>
                </div>

            </td>
        );
    }
}

export default ProjectOverlay;


