import React from "react";
import styles from "../css/Contact.module.css"
import emailjs from "emailjs-com"

import githublogo from "../Logos/githublogo.png"
import linkdenlogo from "../Logos/linkdenlogo.png"
import sendmessageIcon from "../Logos/sendmessageicon.png"

type P = {

};

type S = {

};

class Contact extends React.Component<P, S> {

    constructor(props: P) {
        super(props)

        this.state = {

        }
    }

    submitEmail = (e: React.FormEvent<HTMLFormElement>) => {

        emailjs.sendForm(
            process.env.REACT_APP_SERVICE_ID as string,
            process.env.REACT_APP_TEMPLATE_ID as string,
            e.currentTarget,
            process.env.REACT_APP_PUBLIC_KEY as string
        ).then((result) => {
            console.log(result.text)
        }, (error) => {
            console.log(error.text)
        })
    }

    render() {

        return (
            <div className={styles.container}>

                <form className={styles.emailform} onSubmit={(e) => this.submitEmail(e)}>

                    <h1>Get In Touch!</h1>

                    <div style={{ marginBottom: "30px", color:"#B6BBC4", fontSize:"1.7vh"}}>
                        If you want to get in touch or if you need someone to design and build custom software for your
                        company, that's me. You can contact me via email at <a style={{ color: "#F0ECE5" }} href="mailto:mosesoctsantos@gmail.com">mosesoctsantos@gmail.com</a>, or by using the form below.
                    </div>

                    <div>

                        <div className={styles.formElement}>

                            <input
                                className={styles.emailInput}
                                id="from_name"
                                type="text"
                                name="from_name"
                                autoComplete="off"
                                color="white"
                                placeholder="Your Name"
                                required
                            />
                        </div>

                        <div className={styles.formElement}>
                            <input
                                className={styles.emailInput}
                                id="from_email"
                                type="email"
                                name="from_email"
                                autoComplete="off"
                                placeholder="Your Email"
                                required
                            />
                        </div>
                    </div>
                    <textarea name="message" className={styles.messageArea} rows={5} placeholder="Enter your message.." required />
                    
                    <button type="submit" className={styles.sendmsgButton}> 

                        <div style={{display:"flex", alignItems:"center", color:"#F0ECE5"}}>
                            <img typeof="submit "src={sendmessageIcon} alt="" style={{marginRight:"5px"}}/> 
                            <p>Send Message</p>
                        </div>
                    </button>    
                </form>


                <div className={styles.socials}>

                    <h1>Socials</h1>

                    <div style={{color:"#B6BBC4", fontSize:"1.7vh"}}>
                        Connect with me through social media.
                    </div>

                    <div style={{ flexDirection: "row", display: "flex"}}>

                        <div 
                            className={styles.socialButtonCont}
                            onClick={() => window.open("https://github.com/MosesSantos")}
                        >
                            <img src={githublogo} alt="" onClick={() => window.open("https://github.com/MosesSantos")} />
                            Github
                        </div>

                        <div 
                            className={styles.socialButtonCont}
                            onClick={() => window.open("https://www.linkedin.com/in/moses-santos-8986aa187/")}
                        >
                            <img src={linkdenlogo} alt=""/>
                            LinkedIn
                        </div>
                    </div>

                </div>
            </div>
        );
    }
}

export default Contact;