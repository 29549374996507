import React from "react";

import styles from "../css/Header.module.css"

import About from "../Pages/About";
import Experience from "../Pages/Experience";
import Projects from "../Pages/Projects";
import Contact from "../Pages/Contact";

type P = {
    renderProvider: (component: React.ReactElement) => void
};

type S = {
    buttons: Array<HTMLElement>

};

enum ButtonIndex {
    "about" = 0,
    "experience",
    "projects",
    "contact"
}

class Header extends React.Component<P, S> {

    constructor(props: P) {
        super(props);

        this.state = {
            buttons: []
        }
    }

    componentDidMount(): void {
        this.props.renderProvider(<About />);

        this.setState({
            buttons: [
                (document.getElementById("about") as HTMLElement),
                (document.getElementById("experience") as HTMLElement),
                (document.getElementById("projects") as HTMLElement),
                (document.getElementById("contacts") as HTMLElement)
            ]
        }, () => this.state.buttons[ButtonIndex.about].style.borderBottom = ".2vh solid #F0ECE5")
    }

    buttonFocus = (index: ButtonIndex) => {

        for (let i = 0; i < this.state.buttons.length; i++) {

            if (index === i) {
                this.state.buttons[i].style.borderBottom = ".2vh solid #F0ECE5"
            } else {
                this.state.buttons[i].style.borderBottom = "none";
            }
        }
    }

    render() {
        return (
            <div className={styles.header}>

                <div 
                    id="about"
                    className={styles.headerText} 
                    onClick={() => {
                        this.buttonFocus(ButtonIndex.about)
                        this.props.renderProvider(<About />)
                    }}
                >About</div>
                <div 
                    id="experience" 
                    className={styles.headerText}
                    onClick={() => {
                        this.buttonFocus(ButtonIndex.experience)
                        this.props.renderProvider(<Experience />)
                    }}
                >Experience</div>

                <div 
                    id="projects" 
                    className={styles.headerText}
                    onClick={() => {
                        this.buttonFocus(ButtonIndex.projects)
                        this.props.renderProvider(<Projects />)
                    }}
                >Projects</div>
                <div 
                    id="contacts" 
                    className={styles.headerText}
                    onClick={() => {
                        this.buttonFocus(ButtonIndex.contact)
                        this.props.renderProvider(<Contact />)
                    }}
                >Contact</div>
            </div>
        );
    }
}

export default Header;