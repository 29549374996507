import React from "react";
import styles from "../css/Experience.module.css"

import pdf from "../Logos/myresume.pdf"


type P = {

};

type S = {

};

class Experience extends React.Component<P, S> {

    constructor(props: P) {
        super(props)

        this.state = {

        }
    }

    render() {
        return (
            <div className={styles.container}>

                <div className={styles.expinfo}>

                    <div className={styles.experience}>
                        <h3>My Experience</h3>

                        <textarea name="" id="" className={styles.experienceTextArea} disabled={true} >
                        I'm Moses Santos, a seasoned Software Developer based in GTA, with expertise spanning both front-end and back-end development. Over the course of four years, 
                        I've specialized in crafting software solutions that optimize business logistics operations, leaving a tangible impact on efficiency and productivity.
                        At Prolift Solutions Inc, I took on the role of Lead Full-Stack Developer, where I led the development of a comprehensive enterprise resource planning (ERP) 
                        system. This initiative resulted in a remarkable 20% increase in operational efficiency, achieved through close collaboration with various departments to 
                        ensure alignment with organizational requirements. Leveraging React Native, I also spearheaded the creation of a user-friendly mobile application integrated
                        seamlessly with the ERP system, streamlining processes for installers. My skill set extends beyond development to encompass project management, where I've
                        excelled in coordinating cross-functional teams to deliver exceptional outcomes. My educational background includes a solid foundation in Computer
                        Engineering Technology from Algonquin College, where I earned recognition such as the Award of Excellence for my exceptional project contributions. 
                        I'm deeply passionate about innovation, as evidenced by my involvement in projects spanning artificial intelligence, robotics, and compiler design.
                        With each endeavor, I bring a proactive approach to problem-solving and a commitment to delivering impactful solutions.
                        </textarea>

                    </div>

                    <div className={styles.resumeDiv}>
                        <iframe src={pdf +`#toolbar=0&scrollbar=0`} width={"100%"} height={"100%"} style={{ border: "none", background:"none"}} seamless={true}></iframe>
                    </div>

                </div>
            </div>
        );
    }
}

export default Experience;